<template>
  <div class="px-10">
    <v-toolbar dense flat class="mb-10">
      <div class="text-h4 primary--text">ข้อมูลรถผิดระเบียบ</div>
      <div style="width: 400px">
        <v-text-field
          class="ml-10"
          v-model="filter.start_with"
          prepend-inner-icon="mdi-magnify"
          label="ค้นหา ทะเบียน จังหวัด"
          outlined
          rounded
          dense
          single-line
          hide-details
          @keydown="getData"
        ></v-text-field>
      </div>

      <v-spacer></v-spacer>
      <v-btn color="primary" outlined small class="ml-5" @click="add()">
        <v-icon left small> mdi-plus </v-icon>
        เพิ่มข้อมูลรถผิดระเบียบ
      </v-btn>
    </v-toolbar>

    <v-data-table
      class="elevation-2 row-pointer"
      :headers="headers"
      :items="list"
      :loading="loading"
      sort-by="calories"
      :loading-text="$table_loading_text"
      :page.sync="filter.page"
      :items-per-page="filter.item_per_page"
      hide-default-footer
      @page-count="pageCount = $event"
      item-key="_id"
    >
      <template v-slot:footer>
        <table-pagination
          :page.sync="filter.page"
          :itemsPerPage.sync="filter.item_per_page"
          :length="pageCount"
        />
      </template>

      <template v-slot:[`item.ban_date`]="{ item }">
        {{ formatDate(item.ban_date) }}
      </template>

      <template v-slot:[`item.is_inspect_pass`]="{ item }">
        <menu-ship
          v-model="item.is_inspect_pass"
          path="sub_category/changed_sub_category_status"
          :params="{ sub_category_id: item._id }"
          @success="$alertSuccess(), getData()"
        />
      </template>
      <!-------------------------------------ปุ่มเมนูแก้ไขลบ------------------------------------------>
      <template v-slot:[`item.actions`]="{ item }">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" color="grey" small outlined rounded>
              <v-icon>mdi-dots-horizontal</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template>
              <v-list-item dense link @click="edit(item)">
                <v-list-item-title>แก้ไข</v-list-item-title>
              </v-list-item>
              <v-list-item dense link @click="remove(item)">
                <v-list-item-title>ลบ</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import * as moment from 'moment';

export default {
  data: () => ({
    loading: true,
    pageCount: 0,
    list: [],

    headers: [
      { text: 'ทะเบียน', value: 'car_plate' },
      { text: 'จังหวัด', value: 'province', filterable: false },
      { text: 'วันที่ไม่ผ่าน', value: 'ban_date' },
      { text: 'สาเหตุ', value: 'ban_reason' },
      { text: 'หมายเหตุ', value: 'ban_note' },
      { text: 'ตรอ ที่แจ้ง', value: 'ban_from', filterable: false },
      { text: 'สถานะปัจจุบัน', value: 'is_inspect_pass', filterable: false },
      { text: '', value: 'actions', sortable: false, filterable: false }
    ],

    filter: {
      item_per_page: 20,
      page: 1,
      start_with: '',
      branch_id: 0
    }
  }),
  created() {
    this.getData();
  },

  methods: {
    add() {
      this.$router.push({ name: 'car-bancar-create' });
    },
    edit(item) {
      this.$router.push({
        name: 'car-bancar-update',
        params: { id: item._id }
      });
    },
    remove(item) {
      this.$alertConfirm({ title: `ยืนยันลบข้อมูล ?`, text: `` }).then(
        async result => {
          if (result.isConfirmed) {
            let body = {
              token: this.$jwt.sign(
                { ban_car_id: item._id },
                this.$privateKey,
                { noTimestamp: true }
              )
            };
            await this.$axios
              .post(`${this.$baseUrl}/ban_car/delete_ban_car`, body)
              .then(res => {
                this.$alertSuccess({ title: res.status });
                this.getData();
              })
              .catch(err => {
                this.$alertServerError({ title: err.error_message });
              });
            console.log('remove()');
          }
        }
      );
    },
    async getData() {
      this.loading = true;
      let body = {
        token: this.$jwt.sign(this.filter, this.$privateKey, {
          noTimestamp: true
        })
      };
      await this.$axios
        .post(`${this.$baseUrl}/ban_car/get_ban_car`, body)
        .then(res => {
          this.list = res.result.data;
          this.pageCount = res.result.total_page || 1;
        })
        .catch(err => {
          console.log('err', err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
    },
    formatDate(date) {
      if (!date) return '';
      let year = parseInt(moment(date).format('YYYY'));
      let m_d = moment(date).format('DD/MM');
      return `${m_d}/${year + 543}`;
    }
  },
  computed: {
    isActive() {
      return this.is_inspect_pass == true;
    }
  }
};
</script>
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
